import './style.scss';
import Swiper, { Navigation, EffectFade, Pagination, Controller } from 'swiper';
import { pushAnalyticSlidePosition } from '../../../js/libs/customAnalytics/eventEmiters/sliderNavigation';

export default !function () {
  const blocks = document.querySelectorAll('.universal-gallery-desc-v1');

  if (!blocks.length) {
    return;
  }

  Swiper.use([Navigation, EffectFade, Pagination, Controller]);

  const mobile = window.matchMedia('(max-width: 1023.9px)');
  const textItemSelector = 'universal-gallery-desc-v1__item--text';
  const mediaItemSelector = 'universal-gallery-desc-v1__item--media';

  blocks.forEach((block) => {
    const slidersContainer = block.querySelector('.universal-gallery-desc-v1__sliders');
    const textSliderContainer = slidersContainer.querySelector('.universal-gallery-desc-v1__text-slider');
    const mediaSlides = slidersContainer.querySelectorAll(`.${mediaItemSelector}`);
    const textSlides = textSliderContainer.querySelectorAll(`.${textItemSelector}`);
    const controls = slidersContainer.querySelector('.universal-gallery-desc-v1__controls');
    const isInit = mediaSlides.length > 1 && textSlides.length > 1;

    if (!isInit) {
      controls.remove();
      return;
    }

    let mediaSwiper = null;
    let textSwiper = null;

    const initSwipers = () => {
      textSwiper = new Swiper(textSliderContainer, {
        slidesPerView: 'auto',
        autoHeight: true,

        effect: 'fade',
        fadeEffect: {
          crossFade: true
        },

        wrapperClass: 'universal-gallery-desc-v1__list--text',
        slideClass: textItemSelector,
        noSwipingClass: textItemSelector,

        breakpoints: {
          1024: {
            width: 368,
            autoHeight: false,
            loop: true
          },
          1280: {
            width: 468
          }
        }
      });

      mediaSwiper = new Swiper(slidersContainer, {
        slidesPerView: 'auto',
        spaceBetween: 8,

        effect: mobile.matches ? 'slide' : 'fade',
        fadeEffect: {
          crossFade: true
        },

        wrapperClass: 'universal-gallery-desc-v1__list--media',
        slideClass: mediaItemSelector,

        pagination: {
          el: '.js-slider-count',
          type: 'fraction',
        },

        navigation: {
          nextEl: '.button-arrow--next',
          prevEl: '.button-arrow--prev'
        },

        controller: {
          control: textSwiper
        },

        breakpoints: {
          768: {
            spaceBetween: 24
          },
          1024: {
            spaceBetween: 0,
            width: 504,
            loop: true
          },
          1280: {
            width: 644
          }
        },
        on: {
          realIndexChange: (mediaSwiper) => {
            pushAnalyticSlidePosition(mediaSwiper);
          }
        }
      });
    };

    const mediaChangeListener = () => {
      mediaSwiper && mediaSwiper.destroy();
      textSwiper && textSwiper.destroy();
      initSwipers();
    }

    mediaChangeListener();
    mobile.addEventListener('change', mediaChangeListener);
  });
}();
